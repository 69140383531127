
import Vue from 'vue'

import ImgNoAccess from '@/components/images/ImgNoAccess.vue'

export default Vue.extend({
  name: 'PreTrial',
  components: { ImgNoAccess },
  // If flag has changes since last time HCP checked the page
  created() {
    const isStillPreTrial = Vue.$appSettings.preTrial
    if (!isStillPreTrial) {
      this.$router.push({
        path: '/sites',
      })
    }
  },
})
